import React, { useEffect, useState } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { getRole, beforeRole } from "views/AdminStaff/permissions/permissions.actions";
import Footer from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar";
import routes from "routes.js";
import image3 from "assets/img/full-screen-image-3.jpg";
var CryptoJS = require("crypto-js");
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

let ProtectedArrayProperties = [
	
    // dashboard
	{
		viewRole: false,
		url: '/admin/permissions',
        navigateTo: '/admin'
	},
	{
		viewAdmin: false,
		url: '/admin/staff',
        navigateTo: '/admin'
	},
	{
		viewCounty: false,
		url: '/admin/county',
        navigateTo: '/admin'
	}
];

const Admin = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [routesState, setRoutesState] = useState(routes);
	const [permissions, setPermissions] = useState({});

	useEffect(() => {
		let roleEncrypted = localStorage.getItem('role');
		let role = '';
        if (roleEncrypted) {
            let roleDecrypted = CryptoJS.AES.decrypt(roleEncrypted, 'secret key 123').toString(CryptoJS.enc.Utf8);
			role = roleDecrypted;
		}
		if (role) {
			dispatch(getRole(role));
			dispatch(beforeRole());
		} else {
			localStorage.removeItem("accessToken");
			navigate('/');
		}
	}, [dispatch, navigate]);

	useEffect(() => {
		if (Object.keys(permissions).length > 0) {
			let data = permissions;
			let path = window.location.pathname;

			ProtectedArrayProperties.forEach((val) => {
				for (const key in data) {
					if (key === Object.keys(val)[0] && Object.values(val)[1] === path && data[key] === false) {
						navigate(Object.values(val)[2]);
					}
				}
			});
		}
	}, [permissions, navigate]);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
				document.documentElement.classList.toggle("nav-open");
			}
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div>
			{
				localStorage.getItem("accessToken") ?
					<div className={`wrapper`}>
						<Sidebar {...props} routes={routesState} image={image3} background={'black'} />
						<div id="main-panel" className="main-panel" >
							<AdminNavbar {...props} navigate={navigate} />
							<div className="content">
								{props.children}
							</div>
							<Footer />
						</div>
					</div>
					: navigate('/')
			}
		</div>
	);
};

export default Admin;
