import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSearchParams } from 'react-router-dom';
import {
	getProperty,
	importProperties,
	exportProperties,
	getPropertys,
	getPropertyFiles,
	deleteProperty,
	beforeProperty,
	deleteBulkProperty,
	updateProperty
} from 'views/Property/Property.action'
import { getCountys, beforeCounty } from 'views/County/County.action'
import { getPermission } from '../AdminStaff/permissions/permissions.actions'
import FullPageLoader from 'components/FullPageLoader/FullPageLoader'
import RangePicker from 'components/RangePicker/RangePicker'
import Swal from 'sweetalert2'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import {
	Button,
	Card,
	Form,
	Table,
	Container,
	Row,
	Col,
	Accordion,
} from 'react-bootstrap'
import Select from 'react-select'
var CryptoJS = require('crypto-js')
import PropertyModal from './PropertyModal'
import PropertyImportModal from './PropertyImportModal'
import { ENV } from '../../config/config';

const Property = props => {
	const [currentUserRole, setCurrentUserRole] = useState(null)
	const [propertys, setPropertys] = useState({})
	const [isLoader, setLoader] = useState(true)
	const [isPropertyImportLoader, setPropertyImportLoader] = useState(false)
	const [limit, setLimit] = useState(10)
	const limitOptions = [
		{
			value: 10,
			label: "10"
		},
		{
			value: 25,
			label: "25"
		},
		{
			value: 50,
			label: "50"
		},
		{
			value: 150,
			label: "150"
		},
		{
			value: 250,
			label: "250"
		},
		{
			value: -1,
			label: "All"
		},
	]
	const [page, setPage] = useState(
		localStorage.getItem('pageStateProperty')
			? localStorage.getItem('pageStateProperty')
			: 1
	)
	const [pages, setPages] = useState(0)
	const [total, setTotal] = useState(0)
	const [importModal, setImportModal] = useState(false)
	const [countyOptions, setCountyOptions] = useState([])
	const [propertyFileOptions, setPropertyFileOptions] = useState([])
	const [modalType, setModalType] = useState()
	const [propertyModal, setPropertyModal] = useState(false)
	const [property, setProperty] = useState({})
	const [bulkSelection, setBulkSelection] = useState([])
	const [searchDisabled, setSerachDisabled] = useState(false)
	
	const [search, setSearch] = useState({
		county: '',
		importFile: '',
		ownerName: '',
		billNumber: '',
		oldBillNumber: '',
		parcelId: '',
		address: '',
		tags: '',
		billAmountFrom: '',
		billAmountTo: '',
		currentDueFrom: '',
		currentDueTo: '',
	})
	// set modal type
	const setModal = (type = 1, property = {}) => {
		setPropertyModal(!propertyModal)
		setModalType(type)
		setLoader(false)
		if ((type === 2 || type === 3) && property) {
			setProperty(property)
		}
	}
	const deleteHandler = propertyId => {
		Swal.fire({
			title: 'Are you sure you want to delete?',
			html: 'If you delete a record, it would be permanently lost.',
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Delete'
		}).then(async result => {
			if (result.value) {
				setLoader(true)
				props.deleteProperty(propertyId, search)
			}
		})
	}
	const onSearch = () => {
		props.getPropertys(1, limit, search)
		setLoader(true)
	}
	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			e.preventDefault()
			onSearch()
		}
	}
	const reset = () => {
		setLoader(true)
		setSearch({
			county: '',
			importFile: '',
			ownerName: '',
			billNumber: '',
			oldBillNumber: '',
			parcelId: '',
			address: '',
			tags: '',
			billAmountFrom: '',
			billAmountTo: '',
			currentDueFrom: '',
			currentDueTo: '',
		})
		props.getPropertys(1, limit, '')
	}
	const onPageChange = page => {
		props.getPropertys(page, limit, search)
		localStorage.setItem('pageStateProperty', page)
		setLoader(true)
	}
	const refreshData = () => {
		setLoader(true)
		props.getCountys(1, 10, '', 1, 1)
	}
	const areAllKeysEmpty = obj => {
		return Object.values(obj).every(
			value => value === '' || value === null || value === undefined
		)
	}
	const onBulkCheck = state => {
		if (state) {
			let checked = []
			propertys.map(property => checked.push(property._id))
			setBulkSelection(checked)
		} else {
			setBulkSelection([])
		}
	}
	const onCheck = (state, val) => {
		if (state) {
			setBulkSelection([...bulkSelection, val])
		} else {
			let selected_ = bulkSelection.filter(item => item !== val)
			setBulkSelection(selected_)
			//un check all
		}
	}
	const onBulkdDelete = () => {
		setLoader(true)
		let propertyIds = bulkSelection
		Swal.fire({
			title: 'Are you sure you want to delete?',
			html: 'If you delete a record, it would be permanently lost.',
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Delete'
		}).then(async result => {
			if (result.value) {
				setLoader(true)
				props.deleteBulkProperty({ propertyIds, search })
			}
		})
	}
	const exportRecords = () => {
		// setLoader(true)
		props.exportProperties(search)
	}	
	
	useEffect(() => {
		let roleEncrypted = localStorage.getItem('role')
		if (roleEncrypted) {
			let roleDecrepted = CryptoJS.AES.decrypt(
				roleEncrypted,
				'secret key 123'
			).toString(CryptoJS.enc.Utf8)
			props.getPermission(roleDecrepted)
			props.beforeProperty()
			props.getPropertys(page, limit, search)
			props.getPropertyFiles(page, limit, {}, true)
			props.beforeCounty()
			props.getCountys(1, 10, '', 1, 1)
			setLoader(true)
		}
	}, [])
	useEffect(() => {
		if (!importModal) {
			setPage(1)
			props.getPropertys(1, limit, search)
			props.beforeProperty()
		}
	}, [importModal])
	useEffect(() => {
		if (props.addPropertyAuth) {
			setPropertyModal(false)
			setPage(1)
			props.getPropertys(1, limit, search)
			props.beforeProperty()
		}
	}, [props.addPropertyAuth])
	useEffect(() => {
		if (props.currentUserPermission.authPermission) {
			setCurrentUserRole(props.currentUserPermission.permission.role)
		}
	}, [props.currentUserPermission.authPermission])
	useEffect(() => {
		if (
			props.getPropertysAuth
		) {
			setLoader(false)
			let data = props.getPropertysRes.data
			setPropertys(data.propertys)
			setPage(data.pagination.page)
			setPages(data.pagination.pages)
			setLimit(data.pagination.limit)
			setTotal(data.pagination.total)

			props.beforeProperty()
		}
	}, [props.getPropertysAuth])
	useEffect(() => {
		if (
			props.county.getCountysAuth &&
			Object.keys(props?.county?.getCountysRes?.data).length > 0
		) {
			let data = props.county.getCountysRes.data
			let options_ = []
			data.countys.map((county, index) => {
				options_.push({
					label: county.name,
					value: county._id
				})
			})
			setCountyOptions(options_)
			props.beforeCounty()
		}
	}, [props.county.getCountysAuth])
	useEffect(() => {
		if (
			props.getPropertyFilesAuth &&
			Object.keys(props?.getPropertyFilesRes?.data).length > 0
		) {
			let data = props.getPropertyFilesRes.data
			let options_ = []
			data.propertyFiles.map((file, index) => {
				options_.push({
					label: file.importedFilename,
					value: file._id
				})
			})
			setPropertyFileOptions(options_)
			props.beforeProperty()
		}
	}, [props.getPropertyFilesAuth])
	useEffect(() => {
		if (props.updatePropertyAuth) {
			setPropertyModal(false)
			setLoader(false)
			props.beforeProperty()
			props.getPropertys(1, limit, search)
		}
	}, [props.updatePropertyAuth])
	useEffect(() => {
		if (Object.keys(props.deletePropertyRes).length > 0) {
			setModalType(1)
			setLoader(false)
			props.beforeProperty()
			props.getPropertys(1, limit, search)
			setBulkSelection([])
		}
	}, [props.deletePropertyRes])
	useEffect(() => {
		setSerachDisabled(
			!areAllKeysEmpty(search)
		)

	}, [
		search
	])
	useEffect(() => {
		if (props.exportPropertyAuth) {
			props.beforeProperty()
			const filepath = ENV.exportedRecordFilesPath+props.exportPropertyRes.filename
			const newTab = window.open(filepath, '_blank');
			if (!newTab) {
				alert('Please allow popups for this site');
			}
			setLoader(false)
		}
	}, [props.exportPropertyAuth])
	useEffect(() => {
		if (props.importPropertyAuth) {
			props.beforeProperty()
			props.getPropertys(page, limit, search)
			props.getPropertyFiles(page, limit, {}, true)
			setPropertyImportLoader(false)
		}
	}, [props.importPropertyAuth])

	useEffect(() => {
		setLoader(true)
		props.getPropertys(1, limit, search)
	}, [limit])
	return (
		<>
			{(isLoader || isPropertyImportLoader) ? (
				<FullPageLoader message={isPropertyImportLoader ? 'Importing records...' : 'Loading records...'}/>
			) : (
				<Container fluid>
					<Row>
						<Col md='12'>
							<Card className='filter-card card'>
								<Accordion>
									<Accordion.Item eventKey='0'>
										<Accordion.Header>Filters</Accordion.Header>
										<Accordion.Body>
											<Row>
												<Col xl={3} sm={6} className='search-wrap search-no-wrap'>
													<Form.Group>
														<Form.Label style={{ color: 'black' }} className='d-block mb-2'> Import File </Form.Label>
														<Select placeholder='--- Select import file --- ' options={propertyFileOptions}
															onChange={event => { setSearch({ ...search, importFile: event.value }) }}
															value={propertyFileOptions.filter( option => option.value === search.importFile )} />
													</Form.Group>
												</Col>
												<Col xl={3} sm={6} className='search-wrap search-no-wrap'>
													<Form.Group>
														<Form.Label style={{ color: 'black' }} className='d-block mb-2'> County </Form.Label>
														<Form.Control placeholder='--- Enter County Name ---' name='county'
															onKeyPress={handleKeyPress} value={ENV.capatilize(search.county)}
															onChange={e => setSearch({ ...search, county: e.target.value }) }></Form.Control>
													</Form.Group>
												</Col>
												<Col xl={3} sm={6} className='search-wrap search-no-wrap'>
													<Form.Group>
														<Form.Label style={{ color: 'black' }} className='d-block mb-2'> Owner Name </Form.Label>
														<Form.Control placeholder='--- Enter Owner Name ---' name='ownerName'
															onKeyPress={handleKeyPress} value={ENV.capatilize(search.ownerName)}
															onChange={e => setSearch({ ...search, ownerName: e.target.value }) }></Form.Control>
													</Form.Group>
												</Col>
												<Col xl={3} sm={6} className='search-wrap search-no-wrap'>
													<Form.Group>
														<Form.Label style={{ color: 'black' }} className='d-block mb-2'> Tags  <small style={{color: '#F15927'}}> (comma separated) </small></Form.Label>
														<Form.Control placeholder='--- Enter Tags ---' name='tags'
															onKeyPress={handleKeyPress} value={ENV.capatilize(search.tags)}
															onChange={e => setSearch({ ...search, tags: e.target.value }) }></Form.Control>
													</Form.Group>
												</Col>
												<Col xl={3} sm={6} className='search-wrap search-no-wrap'>
													<Form.Group>
														<Form.Label style={{ color: 'black' }} className='d-block mb-2'> Parcel Id </Form.Label>
														<Form.Control placeholder='--- Enter Parcel Id ---' name='ownerName'
															onKeyPress={handleKeyPress} value={search.parcelId}
															onChange={e => setSearch({ ...search, parcelId: e.target.value }) }></Form.Control>
													</Form.Group>
												</Col>
												<Col xl={3} sm={6} className='search-wrap search-no-wrap'>
													<Form.Group>
														<Form.Label style={{ color: 'black' }} className='d-block mb-2'> Address <small style={{color: '#F15927'}}> (City, State, zipCode or Address) </small></Form.Label>
														<Form.Control placeholder='--- Enter City,State, zipCode or Address ---' name='ownerName'
															onKeyPress={handleKeyPress} value={ENV.capatilize(search.address)}
															onChange={e => setSearch({ ...search, address: e.target.value }) }></Form.Control>
													</Form.Group>
												</Col>
												<Col xl={3} sm={6} className='search-wrap search-no-wrap'>
													<Form.Group>
														<Form.Label style={{ color: 'black' }} className='d-block mb-2'> Bill Amount Range ($) </Form.Label>
														<RangePicker placeholder='Bill Amount' name='billAmount' type='number'
															values={{ from: search.billAmountFrom, to: search.billAmountTo }}
															onChange={values => {
																setSearch({...search, billAmountFrom: values.from, billAmountTo: values.to})
															}}
														></RangePicker>
													</Form.Group>
												</Col>
												<Col xl={3} sm={6} className='search-wrap search-no-wrap'>
													<Form.Group>
														<Form.Label style={{ color: 'black' }} className='d-block mb-2'> Current Due Range ($) </Form.Label>
														<RangePicker placeholder='Current Due' name='billAmount' type='number'
															values={{ from: search.currentDueFrom, to: search.currentDueTo }}
															onChange={values => {
																setSearch({...search, currentDueFrom: values.from, currentDueTo: values.to})
															}}
														></RangePicker>
													</Form.Group>
												</Col>

												<Col xl={3} sm={6}>
													<Form.Group>
														<label className='d-none d-sm-block mb-2 form-label'>
															&nbsp;
														</label>
														<div className='d-flex justify-content-between filter-btns-holder'>
															<button
																type='button'
																className='btn btn-info'
																disabled={!searchDisabled}
																onClick={onSearch}
															>
																Search
															</button>
															<button
																type='button'
																className='btn btn-warning'
																hidden={!searchDisabled}
																onClick={reset}
															>
																Reset
															</button>
														</div>
													</Form.Group>
												</Col>
											</Row>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</Card>
							<Card className='table-big-boy'>
								<Card.Header>
									<Row>
										<Col md={4}>
											<Card.Title as='h4'>Records</Card.Title>
										</Col>
										<Col md={8}>
													
											<div className='d-flex flex-wrap justify-content-end align-items-end gap-2'>
												<Button
													variant='danger'
													className='mb-0 text-nowrap'
													onClick={() => onBulkdDelete()}
												>
													Delete Selected
												</Button>
												<Button
													variant='success'
													className='mb-0 text-nowrap'
													onClick={() => {
														exportRecords()
													}}
												>
													Export Records
												</Button>
												{currentUserRole && currentUserRole.addProperty ? (
													<>
														<Button
															variant='warning'
															className='mb-0 text-nowrap'
															onClick={() => {
																setImportModal(true)
															}}
														>
															Import Records
														</Button>
														<PropertyImportModal setLoader={setPropertyImportLoader} setImportModal={setImportModal} importModal={importModal} countyOptions={countyOptions} />
													</>
												) : (
													''
												)}
												
											</div>

										</Col>
									</Row>
									<div className='d-flex justify-content-end mb-2 pr-3 align-items-center'>
										Show &nbsp; 
										<Select
											onChange={event => {
												setLimit(event.value)
											}}
											options={limitOptions}
											value={limitOptions.filter(
												option => option.value === limit
											)}
										/> &nbsp; records out of &nbsp;
										<span
											style={{ fontWeight: 'bold' }}
										>{` Total : ${total}`}</span>
									</div>
								</Card.Header>
								<Card.Body className='table-full-width'>
									<Container fluid>
										<div className='table-responsive'>
											<Table className='table-striped table-hover align-middle'>
												<thead>
													<tr>
														<th
															style={{ textWrap: 'nowrap' }}
															className='text-center serial-col'
														>
															<Form.Check
																type='checkbox'
																value='all'
																onChange={e => onBulkCheck(e.target.checked)}
																checked={
																	propertys.length == bulkSelection.length
																		? 'checked'
																		: ''
																}
															/>
														</th>
														<th
															style={{ textWrap: 'nowrap' }}
															className='text-center serial-col'
														>
															#
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															Parcel Id
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															Owner Name
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															Tags
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															Address
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															Amounts
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															Import File
														</th>
														<th style={{ textWrap: 'nowrap' }} className='td-action' >
															Actions
														</th>
													</tr>
												</thead>
												<tbody>
													{propertys && propertys.length > 0 ? (
														propertys.map((val, key) => {
															return (
																<>
																	<tr key={key}>
																		<td className='text-center serial-col'>
																			<Form.Check
																				type='checkbox'
																				value={val._id}
																				onChange={e =>
																					onCheck(
																						e.target.checked,
																						e.target.value
																					)
																				}
																				checked={
																					bulkSelection.includes(val._id)
																						? 'checked'
																						: ''
																				}
																			/>
																		</td>
																		<td className='text-center serial-col'>{limit * page - limit + key + 1}</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			{val.parcelId}
																		</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			<p class="mb-0"><strong>First Name: </strong>{ENV.capatilize(val.ownerFirstName)}</p>
																			<p class="mb-0"><strong>Last Name: </strong>{ENV.capatilize(val.ownerLastName)}</p>
																			<p class="mb-0"><strong>Middle Name: </strong>{ENV.capatilize(val.ownerMiddleName)}</p>
																		</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			{val.tags && val.tags.length > 0 ? (
																				val.tags.map((tag, index) => (
																					<span 
																						key={index}
																						className="badge rounded-pill bg-primary me-1 mb-1"
																						style={{ fontSize: '0.85em' }}
																					>
																						{tag}
																					</span>
																				))
																			) : (
																				<span className="text-muted">No tags</span>
																			)}
																		</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			<p class="mb-0"><strong>Full Address: </strong>{ENV.capatilize(val.fullAddress)}</p>
																			<p class="mb-0"><strong>City: </strong>{ENV.capatilize(val.city)}</p>
																			<p class="mb-0"><strong>State: </strong>{ENV.capatilize(val.state)}</p>
																			<p class="mb-0"><strong>ZipCode: </strong>{ENV.capatilize(val.zipcode)}</p>
																			<p class="mb-0"><strong>County: </strong>{ENV.capatilize(val.county)}</p>
																			<p class="mb-0"><strong>Address: </strong>{ENV.capatilize(val.address)}</p>
																			<a 
																				href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
																					`${val.fullAddress || ''} ${val.city || ''} ${val.state || ''} ${val.zipcode || ''}`
																				)}`}
																				target="_blank"
																				rel="noopener noreferrer"
																				className="btn btn-sm btn-info mt-1"
																			>
																				<i className="fa fa-map-marker"></i> View on Maps
																			</a>
																		</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			<p class="mb-0"><strong>Bill Amount: </strong> ${val.billAmount}</p>
																			<p class="mb-0"><strong>Current Due: </strong> ${val.currentDue}</p>
																		</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			<a href={process.env.REACT_APP_ASSETS_BASE_URL + `importFiles/` + val.importfile.importedFilename} target="_blank">{val.importfile.importedFilename}</a>
																		</td>
																		<td className='text-center'>
																			<div className='btn-group'>
																				{currentUserRole?.viewProperty ? (
																					<a
																						className='btn-action btn-primary'
																						title='View'
																						onClick={() => setModal(2, val)}
																					>
																						<i className='fa fa-eye' />
																					</a>
																				) : (
																					<></>
																				)}
																				{currentUserRole?.editProperty ? (
																					<>
																						<a
																							className='btn-action btn-warning'
																							title='Edit'
																							onClick={() => setModal(3, val)}
																						>
																							<i className='fa fa-edit' />
																						</a>
																					</>
																				) : (
																					<></>
																				)}
																				{currentUserRole?.deleteProperty ? (
																					<a
																						className='btn-action btn-danger'
																						title='Delete'
																						onClick={() =>
																							deleteHandler(val._id)
																						}
																					>
																						<i className='fa fa-trash' />
																					</a>
																				) : (
																					<></>
																				)}
																			</div>
																		</td>
																	</tr>
																</>
															)
														})
													) : (
														<tr>
															<td className='text-center px-0' colSpan='100'>
																<span className='alert alert-info d-block text-center'>
																	No Record Found
																</span>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
											<Col className='pb-4'>
												<Pagination
													defaultCurrent={2}
													pageSize // items per page
													current={page} // current active page
													total={pages} // total pages
													onChange={onPageChange}
													locale={localeInfo}
												/>
											</Col>
										</div>
									</Container>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<PropertyModal
						modalType={modalType}
						setModalType={setModalType}
						propertyModal={propertyModal}
						setPropertyModal={setPropertyModal}
						setLoader={setLoader}
						property={property}
						setProperty={setProperty}
						countyOptions={countyOptions}
						refreshData={refreshData}
					/>
				</Container>
			)}
		</>
	)
}

const mapStateToProps = state => ({
	getPropertysRes: state.property.getPropertysRes,
	getPropertysAuth: state.property.getPropertysAuth,
	getPropertyFilesRes: state.property.getPropertyFilesRes,
	getPropertyFilesAuth: state.property.getPropertyFilesAuth,
	getPropertyAuth: state.property.getAuth,
	addPropertyAuth: state.property.addPropertyAuth,
	currentUserPermission: state.role,
	deletePropertyRes: state.property.deletePropertyRes,
	updatePropertyAuth: state.property.updatePropertyAuth,
	importPropertyAuth: state.property.importPropertyAuth,
	exportPropertyAuth: state.property.exportPropertyAuth,
	exportPropertyRes: state.property.exportPropertyRes,
	county: state.county
})

export default connect(mapStateToProps, {
	getProperty,
	getPropertys,
	getPropertyFiles,
	deleteProperty,
	beforeProperty,
	getPermission,
	getCountys,
	beforeCounty,
	importProperties,
	exportProperties,
	deleteBulkProperty,
	updateProperty
})(Property)
