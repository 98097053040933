import axios from "axios";
import { ENV } from '../config/config';
import { GET_ERRORS } from '../redux/types'
import { toast } from 'react-toastify';

export const permissionsForComponents = [
  /**  system permissions **/

  // dashboard
  { role: 'viewDashboard', component: 'Dashboard' },

  // Admin records
  { role: 'viewAdmin', component: 'Admin Staff' },
  { role: 'addAdmin', component: 'Add Admin' },
  { role: 'editAdmin', component: 'Edit Admin' },
  { role: 'deleteAdmin', component: 'Delete Admin' },
  
  // roles & permissions
  { role: 'viewRole', component: 'Roles' },
  
  // Admin county
  { role: 'viewCounty', component: 'County' },
  { role: 'addCounty', component: 'Add County' },
  { role: 'editCounty', component: 'Edit County' },
  { role: 'deleteCounty', component: 'Delete County' },
  
  // Admin property
  { role: 'viewProperty', component: 'Property' },
  { role: 'addProperty', component: 'Add Property' },
  { role: 'editProperty', component: 'Edit Property' },
  { role: 'deleteProperty', component: 'Delete Property' },
  
 // settings
 { role: 'viewSetting', component: 'Site Settings' },

]

export const currencyFormat = (amount, currencyCode = "EUR", currencySymbol = "€") => {
  // return currencyCode+" "+parseFloat(amount).toFixed(2)+" "+currencySymbol
  // return currencySymbol + " " + parseFloat(amount).toFixed(2) + " " + currencyCode
  return currencySymbol + " " + parseFloat(amount).toFixed(2)
}

export const apiHelper =async (apiType, path, data) => {
  if (apiType === 'post' || apiType === 'put' || apiType === 'get' || apiType === 'delete') {
    try {
      let response =await axios({
        method: apiType,
        url: path,
        data,
        headers: {
          'Authorization': ENV.Authorization,
          'x-auth-token': ENV.x_auth_token
        }
      })
      
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        const { data } = error.response
        if (data.message)
          toast.error(data.message)
      }

    }
  }
}