import { BEFORE_PROPERTY, GET_PROPERTY, ADD_PROPERTY, GET_PROPERTYS, GET_PROPERTYFILES, DELETE_PROPERTY, UPDATE_PROPERTY, IMPORT_PROPERTY, EXPORT_PROPERTIES } from '../../redux/types';

const initialState = {
    property: null,
    addPropertyAuth: false,
    addPropertyRes: {},
    importPropertyAuth: false,
    importPropertyRes: {},
    exportPropertyAuth: false,
    exportPropertyRes: {},
    updatePropertyAuth: false,
    updatePropertyRes: {},
    getPropertysAuth: false,
    getPropertysRes: {},
    getPropertyFilesAuth: false,
    getPropertyFilesRes: {},
    deletePropertyRes: {},
    deletePropertyAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case IMPORT_PROPERTY:
            return {
                ...state,
                importPropertyRes: action.payload,
                importPropertyAuth: true
            }
        case EXPORT_PROPERTIES:
            return {
                ...state,
                exportPropertyRes: action.payload,
                exportPropertyAuth: true
            }
        case ADD_PROPERTY:
            return {
                ...state,
                addPropertyRes: action.payload,
                addPropertyAuth: true
            }
        case UPDATE_PROPERTY:
            return {
                ...state,
                updatePropertyRes: action.payload,
                updatePropertyAuth: true
            }
        case GET_PROPERTY:
            return {
                ...state,
                property: action.payload,
                getAuth: true
            }
        case GET_PROPERTYS:
            return {
                ...state,
                getPropertysRes: action.payload,
                getPropertysAuth: true
            }
        case GET_PROPERTYFILES:
            return {
                ...state,
                getPropertyFilesRes: action.payload,
                getPropertyFilesAuth: true
            }
        case DELETE_PROPERTY:
            return {
                ...state,
                deletePropertyRes: action.payload,
                deletePropertyAuth: true
            }
        case BEFORE_PROPERTY:
            return {
                ...state,
                property: null,
                addPropertyAuth: false,
                addPropertyRes: {},
                importPropertyAuth: false,
                importPropertyRes: {},
                exportPropertyAuth: false,
                exportPropertyRes: {},
                updatePropertyAuth: false,
                updatePropertyRes: {},
                getPropertysAuth: false,
                getPropertysRes: {},
                getPropertyFilesAuth: false,
                getPropertyFilesRes: {},
                deletePropertyRes: {},
                deletePropertyAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}