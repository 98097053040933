import React, { useEffect, useState, useMemo } from 'react';
import './RangePicker.css';
import {Form} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
/**
 * 
 * @param { placeholder, name, type, onChange, values:{to, from} } props 
 * @returns 
 */
function RangePicker(props) {
    const [from, setFrom] = useState(props?.values?.from)
    const [to, setTo] = useState(props?.values?.to)
    
    useEffect(()=>{
        props.onChange({from, to})
    },[to, from])
    return (
        <div className="d-flex justify-content-between">
            <Form.Control 
                type={props.type ? props.type  : 'text'}
                className="range-fields from-range-field" 
                value={from} 
                onChange={(e) => setFrom(e.target.value)} 
                placeholder={`Enter ${props.placeholder} From`} 
                name={`${props.name}From`} 
            ></Form.Control>
            <div className="range-icon-holder">
                <FontAwesomeIcon icon={faArrowRight} />
            </div>
            <Form.Control 
                type={props.type ? props.type  : 'text'}
                className="range-fields to-range-field" 
                value={to} 
                onChange={(e) => setTo(e.target.value)} 
                placeholder={`Enter ${props.placeholder} To`} 
                name={`${props.name}To`} 
            ></Form.Control>
        </div>
    );
};

export default React.memo(RangePicker);