import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Button, Form, Container, Row, Modal, Col } from "react-bootstrap";
import { ReactSpreadsheetImport } from 'react-spreadsheet-import'
import {
	importProperties,
	beforeProperty,
} from 'views/Property/Property.action'
const PropertyImportModal = (props) => {
	const [isOpen, setIsOpen] = useState(false)

	const onClose = () => {
		onCloseHandler()
		setIsOpen(false)
	}
	const openImportModal = () => {
		onCloseHandler()
		setIsOpen(true)
	}
	const onCloseHandler = () => {
		props.setImportModal(false)
	}
	const onSubmit = (data, file) => {
			let properties = data.validData
			const payload = new FormData();
			payload.append('importFile', file);
			payload.append('properties', JSON.stringify(properties));
			props.beforeProperty()
			props.importProperties(payload)
			props.setLoader(true)
	}
	const fields = [
		{
			label: 'Bill #',
			key: 'billNumber',
			alternateMatches: ['Bill #', 'Bill#'],
			fieldType: {
				type: 'input'
			},
			example: '001200001'
		},
		{
			label: 'Old Bill #',
			key: 'oldBillNumber',
			alternateMatches: ['Old Bill #', 'Old Bill#'],
			fieldType: {
				type: 'input'
			},
			example: '001200001'
		},
		{
			label: 'Parcel Id',
			key: 'parcelId',
			alternateMatches: ['Parcel #', 'Parcel#'],
			fieldType: {
				type: 'input'
			},
			example: '001200001'
		},
		{
			label: 'Name',
			key: 'ownerFullName',
			alternateMatches: ['name', 'Name', 'owner name', 'ownername'],
			fieldType: {
				type: 'input'
			},
			example: 'John'
		},
		{
			label: 'Address',
			key: 'fullAddress',
			alternateMatches: ['location', 'address'],
			fieldType: {
				type: 'input'
			},
			example: '1021 Louise RD WS 27107 NC'
		},
		{
			label: 'County',
			key: 'countyName',
			alternateMatches: ['county'],
			fieldType: {
				type: 'input'
			},
			example: 'NC'
		},
		{
			label: 'Tag',
			key: 'tags',
			alternateMatches: ['tag', 'tags', 'flag', 'flags', 'bill flag', 'bill flags'],
			fieldType: {
				type: 'input'
			},
			example: 'Deliquient, Taxable, Tax Deliquent'
		},
		{
			label: 'Bill Amount',
			key: 'billAmount',
			alternateMatches: ['Bill Amount'],
			fieldType: {
				type: 'input'
			},
			example: '4256'
		},
		{
			label: 'Current Due',
			key: 'currentDue',
			alternateMatches: ['Current Due'],
			fieldType: {
				type: 'input'
			},
			example: '9874'
		},
	]

	useEffect(() => {
		if (props.importPropertyAuth) {
			props.setLoader(false)
		}
	}, [props.importPropertyAuth])
	
	return (
		<Container fluid>
			{
				<Modal className="modal-primary staff-modal" id="admin-modal" onHide={() => { onCloseHandler() }} show={props.importModal} >
					<Modal.Header className="justify-content-center">
						<Row>
							<div className="col-12">
								<h4 className="mb-0 mb-md-3 mt-0">
									Import Records
								</h4>
							</div>
						</Row>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<Form>
							<Row>
								<Col md={12} className="text-center">
									<p>Please select the file to import. The file will be imported according to the column headers mapped with the fields in the system.</p>
									<p>If the column headers do not match the fields in the system, you can map the columns to the fields manually.</p>
									<p>Once the file is imported, you can filter the records by the fields in the system.</p>
									<p>You can also tag the records with custom tags and add additional tags later.</p>
									<p>You can also search the records by the fields in the system.</p>
								</Col>
								<Col md={12} className="mt-3 text-center">
									<Button className="btn btn-info" onClick={() => { openImportModal() }} >Import Excel File </Button>
								</Col>
							</Row>
						</Form>
					</Modal.Body>

					<Modal.Footer>
						<Button className="btn btn-warning" onClick={() => { onCloseHandler(); }}>Close</Button>
					</Modal.Footer>
				</Modal>
			}
			<ReactSpreadsheetImport
				isOpen={isOpen}
				onClose={onClose}
				onSubmit={onSubmit}
				fields={fields}
				acceptedFileTypes={[
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					'application/vnd.ms-excel'
				]}
			/>
		</Container>
	)
}

const mapStateToProps = state => ({
	importPropertyRes: state.property.importPropertyRes,
	importPropertyAuth: state.property.importPropertyAuth,
})

export default connect(mapStateToProps, {
	beforeProperty,
	importProperties,
})(PropertyImportModal)
