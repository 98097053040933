import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import validator from 'validator';
import { addRole, updateRole, beforeRole } from './permissions.actions';
import $ from 'jquery';
import { Button, Form, Container, Row, Col, Modal, FormGroup } from "react-bootstrap";

const StaffPermissionModal = (props) => {
    const dispatch = useDispatch()
    const [title, setTitle] = useState('')
    const [status, setStatus] = useState(true)
    const [selectAll, setSelectAll] = useState(false)
    const [titleMsg, setTitleMsg] = useState('')
    const [formValid, setFormValid] = useState(false)
    const [permissions, setPermissions] = useState({
        /**  system permissions **/

        //Dashboard
        viewDashboard: false,

        // admin records
        addAdmin: false,
        editAdmin: false,
        deleteAdmin: false,
        viewAdmin: false,

        // roles
        addRole: false,
        editRole: false,
        deleteRole: false,
        viewRole: false,

        // county
        addCounty: false,
        editCounty: false,
        deleteCounty: false,
        viewCounty: false,
        // Property
        addProperty: false,
        editProperty: false,
        deleteProperty: false,
        viewProperty: false,
        //Settings
        viewSetting: false,
        addSetting: false,
        deleteSetting: false,
        editSetting: false,


    })
    const addRoleRes = useSelector(state => state.role.addRoleRes)
    const updateRoleRes = useSelector(state => state.role.updateRoleRes)
    const authenticate = useSelector(state => state.role.authenticate)
    const onChangeCheckbox = (name, value) => {

        let roles = permissions

        if (name === 'selectAll') {
            Object.keys(roles).forEach((val, key) => {
                if (val !== 'title' && val !== '_id' && val !== 'status' && val !== 'createdAt' && val !== 'updatedAt' && val !== '_v')
                    roles = { ...roles, [val]: value }
            });
            setSelectAll(value)
        }
        else {
            roles = { ...roles, [name]: value }

            // select all state settings

            let count = 0;

            if (props.modalType === 3) {
                count = 1;
            }
            else {
                count = 0;
            }

            Object.keys(roles).forEach((key, index) => {
                if (roles[key] === true && key !== 'status')
                    count++;
            });

            let selectCount = count === 103 ? true : false

            setSelectAll(selectCount)
        }
        setPermissions(roles)
    }
    const submit = (e) => {


        if (title === undefined) {
            setTitleMsg("Title Required.")
            $('.modal-primary').scrollTop(0, 0)
            setFormValid(true)
        }
        else {
            if (!validator.isEmpty(title)) {
                setTitleMsg(title)
                setFormValid(false)

                const role = { ...permissions, title, status }


                if (props.modalType === 1) // add modal type
                {

                    let check = true
                    if (props.roles.length > 0) {
                        props.roles.map((item, index) => {
                            if (item.title.toUpperCase().replace(/\s/g, '').trim() === title.toUpperCase().replace(/\s/g, '').trim()) {

                                setTitleMsg("Title already exist.")
                                check = false
                            }
                        })
                    }

                    if (check == true) {
                        props.setLoader(true)
                        dispatch(addRole(role));
                    }


                }
                else if (props.modalType === 3) // update modal type
                {
                    props.setLoader(true)
                    dispatch(updateRole(role));

                }

                setPermissions(role)
                props.setData(role)
                props.setLoader(true)

            }
            else {
                setTitleMsg("Title Required.")
                $('.modal-primary').scrollTop(0, 0)
                setFormValid(true)
            }
        }
    }

    useEffect(() => {
        if (Object.keys(props.role).length > 0) {
            if (props.modalType === 1) {
                setEmpty()
            }
            else if (props.modalType === 2 || props.modalType === 3) {
                setPermissions(props.role)
                setTitle(props.role.title)
                setStatus(props.role.status)
            }

        }
    }, [props.role])

    useEffect(() => {
        if (props.modalType === 2) {
            $(".modal-primary input").prop("disabled", true);
        } else {
            $(".modal-primary input").prop("disabled", false);
        }
    }, [props.modalType])



    useEffect(() => {
        if (addRoleRes.success && authenticate === true) {
            props.setroleModal(!props.roleModal)
            props.setModalType('')
            props.setLoader(false)
            setEmpty()
            toast.success(addRoleRes.message);
            dispatch(beforeRole())
        }
    }, [addRoleRes])

    const onCloseHandler = () => {
        props.setroleModal(!props.roleModal)
        setEmpty()
    }
    const resetPermission = () => {
        setPermissions({
            viewDashboard: false,
            // admin records
            addAdmin: false,
            editAdmin: false,
            deleteAdmin: false,
            viewAdmin: false,

            // roles
            addRole: false,
            editRole: false,
            deleteRole: false,
            viewRole: false,

            // county
            addCounty: false,
            editCounty: false,
            deleteCounty: false,
            viewCounty: false,
            // Property
            addProperty: false,
            editProperty: false,
            deleteProperty: false,
            viewProperty: false,
            //Settings
            viewSetting: false,
            addSetting: false,
            deleteSetting: false,
            editSetting: false,
        })
        setTitle('')
    }
    useEffect(() => {
        if (Object.keys(updateRoleRes).length > 0 && authenticate === true) {

            props.setroleModal(!props.roleModal)
            props.setModalType('')
            props.setLoader(false)
            toast.success(updateRoleRes.message);
            dispatch(beforeRole())
        }
    }, [updateRoleRes])

    const setEmpty = () => {
        setTitle('')
        setStatus(true)

        resetPermission()

    }


    return (
        <Container fluid>
            {
                formValid ?
                    <div className="text-danger">Please fill the required fields</div> : null
            }
            {
                props.modalType > 0 &&
                <Modal className="modal-primary" onHide={() => {
                    // props.setroleModal(!props.roleModal);
                    // resetPermission()
                    onCloseHandler();
                    props.setRole({})

                }} show={props.roleModal}>
                    <Modal.Header className="justify-content-center">
                        <Row>
                            <div className="col-12">
                                <h4 className="mb-0 mb-md-3 mt-0">
                                    {props.modalType === 1 ? 'Add New' : props.modalType === 2 ? 'View' : 'Edit'} Staff Role
                                </h4>
                            </div>
                        </Row>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <Form>
                            <Form.Group>
                                <Row>
                                    <Col md={9}>
                                        <label className="label-font">Title <span className="text-danger">*</span></label>
                                        <Form.Control
                                            placeholder="Enter name"
                                            type="text"
                                            name="title"
                                            onChange={(e) => setTitle(e.target.value)}
                                            disabled={props.modalType === 2}
                                            value={title}
                                            required
                                        />
                                        <span className={titleMsg ? `` : `d-none`}>
                                            <label className="pl-1 text-danger">{titleMsg}</label>
                                        </span>
                                    </Col>

                                    <Col md={3}>
                                        <label className="right-label-checkbox">Select All
                                            <input type="checkbox" name="selectAll" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !selectAll)} checked={selectAll} />
                                            <span className="checkmark"></span>
                                        </label>

                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row className="mt-3">
                                    <Col md={3}>
                                        <label className="label-font">Dashboard</label>
                                    </Col>
                                    <Col md={9} className="check-inline d-flex flex-wrap" >
                                        <label className="right-label-checkbox mr-3 mb-2">View
                                            <input type="checkbox" name="viewDashboard" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewDashboard)} checked={permissions.viewDashboard} />
                                            <span className="checkmark"></span>
                                        </label>

                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col md={3}>
                                        <label className="label-font">Admin</label>
                                    </Col>
                                    <Col md={9} className="check-inline d-flex flex-wrap" >
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">View
                                                <input type="checkbox" name="viewAdmin" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewAdmin)} checked={permissions.viewAdmin} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Add
                                                <input type="checkbox" name="addAdmin" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addAdmin)} checked={permissions.addAdmin} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Edit
                                                <input type="checkbox" name="editAdmin" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editAdmin)} checked={permissions.editAdmin} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Delete
                                                <input type="checkbox" name="deleteAdmin" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteAdmin)} checked={permissions.deleteAdmin} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col md={3}>
                                        <label className="label-font">Roles</label>
                                    </Col>
                                    <Col md={9} className="check-inline d-flex flex-wrap">
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">View
                                                <input type="checkbox" name="viewRole" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewRole)} checked={permissions.viewRole} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Add
                                                <input type="checkbox" name="addRole" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addRole)} checked={permissions.addRole} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Edit
                                                <input type="checkbox" name="editRole" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editRole)} checked={permissions.editRole} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Delete
                                                <input type="checkbox" name="deleteRole" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteRole)} checked={permissions.deleteRole} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col md={3}>
                                        <label className="label-font">County</label>
                                    </Col>
                                    <Col md={9} className="check-inline d-flex flex-wrap" >
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">View
                                                <input type="checkbox" name="viewCounty" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewCounty)} checked={permissions.viewCounty} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Add
                                                <input type="checkbox" name="addCounty" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addCounty)} checked={permissions.addCounty} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Edit
                                                <input type="checkbox" name="editCounty" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editCounty)} checked={permissions.editCounty} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Delete
                                                <input type="checkbox" name="deleteCounty" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteCounty)} checked={permissions.deleteCounty} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col md={3}>
                                        <label className="label-font">Property</label>
                                    </Col>
                                    <Col md={9} className="check-inline d-flex flex-wrap" >
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">View
                                                <input type="checkbox" name="viewProperty" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewProperty)} checked={permissions.viewProperty} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Add
                                                <input type="checkbox" name="addProperty" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addProperty)} checked={permissions.addProperty} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Edit
                                                <input type="checkbox" name="editProperty" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editProperty)} checked={permissions.editProperty} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Delete
                                                <input type="checkbox" name="deleteProperty" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteProperty)} checked={permissions.deleteProperty} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col md={3}>
                                        <label className="label-font">Settings</label>
                                    </Col>
                                    <Col md={9} className="check-inline d-flex flex-wrap">
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">View
                                                <input type="checkbox" name="viewSetting" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewSetting)} checked={permissions.viewSetting} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                        <Col md={3}>
                                            <label className="right-label-checkbox mr-3 mb-2">Edit
                                                <input type="checkbox" name="editSetting" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editSetting)} checked={permissions.editSetting} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <FormGroup>
                                <Row>
                                    <Col md={3}>
                                        <label className="label-font">Status</label>
                                    </Col>
                                    <Col md={9} className="check-inline d-flex flex-wrap" >
                                        <Col md={3} >
                                            <label className="right-label-radio mr-3 mb-2">Active
                                                <input name="status" disabled={props.modalType === 2} type="radio" checked={status} value={status} onChange={(e) => setStatus(true)} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col >
                                        <Col md={3} >
                                            <label className="right-label-radio mr-3 mb-2">InActive
                                                <input name="status" disabled={props.modalType === 2} type="radio" checked={!status} value={!status} onChange={(e) => setStatus(false)} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </Col>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className="btn btn-warning" onClick={(e) => { onCloseHandler(); props.setRole({}) }}>Close</Button>
                        {props.modalType === 2 ? '' :
                            <Button className="btn btn-info" onClick={() => { submit(); props.setRole({}) }} /* disabled={isLoader} */>Save</Button>
                        }
                    </Modal.Footer>
                </Modal>
            }
        </Container>
    )
}

export default StaffPermissionModal;