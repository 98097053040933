import { toast } from 'react-toastify';
import { GET_ERRORS, BEFORE_COUNTY, GET_COUNTY, ADD_COUNTY, GET_COUNTYS, DELETE_COUNTY, UPDATE_COUNTY } from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';

export const beforeCounty = () => {
    return {
        type: BEFORE_COUNTY
    }
}

export const addCounty = (body) => dispatch => {
    dispatch(emptyError());
    fetch(`${ENV.url}county/create`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data?.success) {
            toast.success(`${data.message}`);
            dispatch({
                type: ADD_COUNTY,
                payload: data
            });
        } else {
            toast.error(`${data.message}`);
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const deleteCounty = (countyId) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}county/delete/${countyId}`;
    fetch(url, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: DELETE_COUNTY,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error?.response?.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getCounty = (staffId) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}county/get/${staffId}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: GET_COUNTY,
                payload: data.county
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error?.response?.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getCountys = (page = 1, limit = 10, query = [], countyId = "", all = 0) => dispatch => {
    dispatch(emptyError());
    let url = `county/list?all=${all}&page=${page}&limit=${limit}&countyId=${countyId}`;
    if (query !== '' && query !== undefined) {
        url = `county/list?all=${all}&page=${page}&limit=${limit}&countyId=${countyId}&name=${query.name}&status=${query.status}&createdAtStart=${query.createdAtStart}&createdAtEnd=${query.createdAtEnd}`;
    }

    fetch(`${ENV.url}${url}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': localStorage.getItem('accessToken'),
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_COUNTYS,
                payload: data
            })
        }
        else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const updateCounty = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}county/edit`;
    fetch(url, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: UPDATE_COUNTY,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error?.response?.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
