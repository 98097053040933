import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
	beforeProperty,
	getPropertyFiles,
} from 'views/Property/Property.action'
import { getCountys, beforeCounty } from 'views/County/County.action'
import { getPermission } from '../AdminStaff/permissions/permissions.actions'
import FullPageLoader from 'components/FullPageLoader/FullPageLoader'
import Swal from 'sweetalert2'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import {
	Card,
	Table,
	Container,
	Row,
	Col,
} from 'react-bootstrap'
var CryptoJS = require('crypto-js')


const PropertyFilesListing = props => {
	const [propertyFiles, setPropertyFiles] = useState({})
	const [isLoader, setLoader] = useState(true)
	const [limit, setLimit] = useState(10)
	const [page, setPage] = useState(
		localStorage.getItem('pageStateProperty')
			? localStorage.getItem('pageStateProperty')
			: 1
	)
	const [pages, setPages] = useState(0)
	const [total, setTotal] = useState(0)
	const onPageChange = page => {
		props.getPropertys(page, limit, {})
		localStorage.setItem('pageStateProperty', page)
		setLoader(true)
	}
	useEffect(() => {
		props.beforeProperty()
		props.getPropertyFiles(page, limit, {})
		props.beforeCounty()
		props.getCountys(1, 10, '', 1, 1)
		setLoader(true)
	}, [])
	useEffect(() => {
		if (
			props.getPropertyFilesAuth &&
			Object.keys(props?.getPropertyFilesRes?.data).length > 0
		) {
			setLoader(false)
			let data = props.getPropertyFilesRes.data
			setPropertyFiles(data.propertyFiles)
			setPage(data.pagination.page)
			setPages(data.pagination.pages)
			setLimit(data.pagination.limit)
			setTotal(data.pagination.total)

			props.beforeProperty()
		}
	}, [props.getPropertyFilesAuth])

	return (
		<>
			{isLoader ? (
				<FullPageLoader />
			) : (
				<Container fluid>
					<Row>
						<Col md='12'>
							<Card className='table-big-boy'>
								<Card.Header>
									<div className='d-flex justify-content-end mb-2 pr-3'>
										<span
											style={{ fontWeight: 'bold' }}
										>{`Total : ${total}`}</span>
									</div>
									<div className=''>
										<Card.Title as='h4'>Property Files</Card.Title>
									</div>
								</Card.Header>
								<Card.Body className='table-full-width'>
									<Container fluid>
										<div className='table-responsive'>
											<Table className='table-striped table-hover align-middle'>
												<thead>
													<tr>
														<th
															style={{ textWrap: 'nowrap' }}
															className='text-center serial-col'
														>
															#
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															File Name
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															Original File Name
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															Total Records
														</th>
														<th style={{ textWrap: 'nowrap' }}>
															Imported On
														</th>
													</tr>
												</thead>
												<tbody>
													{propertyFiles && propertyFiles.length > 0 ? (
														propertyFiles.map((val, key) => {
															return (
																<>
																	<tr key={key}>
																		<td>{limit * page - limit + key + 1}</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			{val.importedFilename}
																		</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			{val.importedFilename.replace(/_.+\./, '.')}
																		</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			{val.noOfRecords}
																		</td>
																		<td style={{ textWrap: 'nowrap' }}>
																			{val.createdAt}
																		</td>
																	</tr>
																</>
															)
														})
													) : (
														<tr>
															<td className='text-center px-0' colSpan='100'>
																<span className='alert alert-info d-block text-center'>
																	No Record Found
																</span>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
											<Col className='pb-4'>
												<Pagination
													defaultCurrent={2}
													pageSize // items per page
													current={page} // current active page
													total={pages} // total pages
													onChange={onPageChange}
													locale={localeInfo}
												/>
											</Col>
										</div>
									</Container>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			)}
		</>
	)
}

const mapStateToProps = state => ({
	getPropertyFilesRes: state.property.getPropertyFilesRes,
	getPropertyFilesAuth: state.property.getPropertyFilesAuth,
	county: state.county
})

export default connect(mapStateToProps, {
	getPropertyFiles,
	beforeProperty,
	getCountys,
	beforeCounty,
})(PropertyFilesListing)
