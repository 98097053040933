import { BEFORE_COUNTY, GET_COUNTY, ADD_COUNTY, GET_COUNTYS, DELETE_COUNTY, UPDATE_COUNTY } from '../../redux/types';

const initialState = {
    county: null,
    addCountyAuth: false,
    addCountyRes: {},
    updateCountyAuth: false,
    updateCountyRes: {},
    getCountysAuth: false,
    getCountysRes: {},
    deleteCountyRes: {},
    deleteCountyAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_COUNTY:
            return {
                ...state,
                addCountyRes: action.payload,
                addCountyAuth: true
            }
        case UPDATE_COUNTY:
            return {
                ...state,
                updateCountyRes: action.payload,
                updateCountyAuth: true
            }
        case GET_COUNTY:
            return {
                ...state,
                county: action.payload,
                getAuth: true
            }
        case GET_COUNTYS:
            return {
                ...state,
                getCountysRes: action.payload,
                getCountysAuth: true
            }
        case DELETE_COUNTY:
            return {
                ...state,
                deleteCountyRes: action.payload,
                deleteCountyAuth: true
            }
        case BEFORE_COUNTY:
            return {
                ...state,
                county: null,
                addCountyAuth: false,
                addCountyRes: {},
                updateCountyAuth: false,
                updateCountyRes: {},
                getCountysAuth: false,
                getCountysRes: {},
                deleteCountyRes: {},
                deleteCountyAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}