import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import App from "./App";
import store from './store';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";
import 'assets/css/style.css';

import 'react-toastify/dist/ReactToastify.css';

// Get the root element from the DOM
const container = document.getElementById('root');

// Create a root
const root = createRoot(container);

// Render the app to the root
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      {/* <BrowserRouter basename={'/admin'}> */}
      <BrowserRouter basename={'/'}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);