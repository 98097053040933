import { useEffect, useState } from "react";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import { beforeDashboard, getDashboard } from './Dashboard.action';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader'
import DashboardCard from 'components/DashboardCard'
import { connect } from 'react-redux';
import {
	faHouseFlag,faRecordVinyl,faChartArea
} from '@fortawesome/free-solid-svg-icons'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Link } from "react-router-dom";

function Dashboard(props) {

	const [data, setData] = useState({
		totalCounties: 0,
		totalRecords: 0,
		counties: [],
		propertyFiles: []
	})
	const [loader, setLoader] = useState(true)
	useEffect(() => {
		setLoader(true)
		props.beforeDashboard()
		props.getDashboard()
	}, [])

	useEffect(() => {
		if (props.dashboard.dataAuth) {
			setLoader(false)
			setData({ ...data, counties: props.dashboard.data.counties, propertyFiles: props.dashboard.data.propertyFiles, properties: props.dashboard.data.properties[0] })
		}
	}, [props.dashboard.dataAuth])
	return (
		<div className="pt-3 pt-md-5">
			{
				loader ?
					<FullPageLoader />
					:
					<Container fluid>
						<Row>
							<Col xl={4} lg={4} sm={12}>
								<DashboardCard link={'/county'} icon={faChartArea} title={'Total Counties'} count={data.counties.length} />
							</Col>
							<Col xl={4} lg={4} sm={12}>
								<DashboardCard link={'/records'} icon={faRecordVinyl} title={'Total Records'} count={data?.properties?.totalRecords[0]?.count ?? 0} />
							</Col>
							<Col xl={4} lg={4} sm={12}>
								<DashboardCard link={'/property-files'} icon={faHouseFlag} title={'Total Imported Files'} count={data.propertyFiles.length} />
							</Col>
						</Row>
						<Row className="mt-5">
							<ResponsiveContainer width="100%" height={500}>
								<AreaChart
									data={data.counties}
									margin={{
										top: 10,
										right: 30,
										left: 0,
										bottom: 0,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									<Area type="monotone" dataKey="propertiesCount" stackId="1" stroke="#3CFF33" fill="#3CFF33" />
								</AreaChart>
							</ResponsiveContainer>
						</Row>
						<Row className="mt-5">
							<Col lg={12} sm={12}>
								<Card className="card-stats">
									<Card.Body>
										<Card.Title>Counties</Card.Title>
										<div className="table-responsive">
											<Table className="table-bigboy">
												<thead>
													<tr>
														<th className="text-center serial-col">#</th>
														<th className='text-center td-actions'>County Name</th>
														<th className='text-center td-actions'>Total No of Records</th>
													</tr>
												</thead>
												<tbody>
													{
														data.counties && data.counties.length ?
															data.counties.map((item, index) => {
																return (
																	<tr key={index}>
																		<td className="text-center serial-col"> {index + 1}</td>
																		<td className="text-center serial-col">  <Link target="_blank" to={`/records?county=${item._id}`}>{item.name}</Link></td>
																		<td className="text-center serial-col"> <Link target="_blank" to={`/records?county=${item._id}`}>{item.propertiesCount}</Link></td>
																	</tr>
																)
															})
															:
															<tr>
																<td colSpan="10" className="text-center">
																	<div className="alert alert-info" role="alert">No County Found</div>
																</td>
															</tr>
													}
												</tbody>
											</Table>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
			}
		</div>


	);
}

const mapStateToProps = state => ({
	dashboard: state.dashboard,
	user: state.user,
	error: state.error,
});

export default connect(mapStateToProps, { beforeDashboard, getDashboard })(Dashboard);
