import { useState, useEffect, useRef } from "react";
import { ENV } from '../../config/config';
import { getSettings, beforeSettings, editSettings } from './Setting.action';
import { connect } from 'react-redux';
import { getRole } from "views/AdminStaff/permissions/permissions.actions";
import FullPageLoader from "components/FullPageLoader/FullPageLoader";
const CryptoJS = require("crypto-js");
import validator from 'validator';
import userDefaultImg from '../../assets/img/default-profile.png'
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";

const SiteSettings = (props) => {
	const myRef = useRef(null)
	const [permissions, setPermissions] = useState({})
	const [loader, setLoader] = useState(true)
	const [siteLogo, setSiteLogo] = useState('')
	const [errorMessage, setErrorMessage] = useState(false)
	const [msg, setMsg] = useState({
		siteTitle: '',
		siteLogo: '',
		companyName: '',

		email: '',
		phone: '',

		googleAPIKey: '',
	})

	useEffect(() => {
		window.scroll(0, 0)
		const callback = () => {
			setLoader(false);
		}
		props.getSettings(callback)
		const roleEncrypted = localStorage.getItem('role');
		let role = ''
		if (roleEncrypted) {
			const roleDecrypted = CryptoJS.AES.decrypt(roleEncrypted, "secret key 123").toString(CryptoJS.enc.Utf8);
			role = roleDecrypted
		}
		props.getRole(role)
	}, [])

	useEffect(() => {
		if (Object.keys(props.getRoleRes).length > 0) {
			setPermissions(props.getRoleRes.role)
		}
	}, [props.getRoleRes])

	useEffect(() => {
		if (props.settings.settingsAuth) {
			if (props.settings.settings) {
				setLoader(false)
				const { settings: settingsData } = props.settings;
				setSettings({ ...settings, ...settingsData })
				if (siteLogo === '') {
					setSiteLogo(settingsData.siteLogo)
				}
			}
			props.beforeSettings()
		}
	}, [props.settings.settingsAuth])

	const [settings, setSettings] = useState({
		siteTitle: '',
		siteLogo: '',
		companyName: '',

		email: '',
		phone: '',

		googleAPIKey: '',
	})

	const submit = () => {
		let check = true
		setMsg({
			siteTitle: validator.isEmpty(settings.siteTitle) ? 'Site Title is required' : '',
			siteLogo: validator.isEmpty(siteLogo) ? 'Site Logo is required' : '',
			companyName: validator.isEmpty(settings.companyName) ? 'Company Name is required' : '',

			email: validator.isEmpty(settings.email) ? 'Email is required' : !validator.isEmpty(settings.email) && !validator.isEmail(settings.email) ? 'Please input valid email' : '',
			phone: validator.isEmpty(settings.phone) ? 'Phone is required' : '',

			googleAPIKey: validator.isEmpty(settings.googleAPIKey) ? 'google API Key is required' : '',

		})


		if (validator.isEmpty(String(settings.siteTitle))
			|| validator.isEmpty(siteLogo)
			|| validator.isEmpty(settings.companyName)
			|| (validator.isEmpty(String(settings.phone)))
			|| (validator.isEmpty(String(settings.mobile)))
			|| validator.isEmpty(settings.googleAPIKey)
		) {
			check = false
			setErrorMessage(true)
			myRef.current.scrollIntoView()
		}

		if (check) {
			const formData = new FormData()
			for (const key in settings)
				formData.append(key, settings[key])
			if (siteLogo != '') {
				formData.append('siteLogo', siteLogo)
			}
			const qs = ENV.objectToQueryString({ type: '1' })
			props.editSettings(formData, qs)
			setLoader(true)
			setErrorMessage(false)
		}
		else {
			setErrorMessage(true)
			myRef.current.scrollIntoView()
		}
	}
	return (
		<>
			{
				loader ? <FullPageLoader /> :
					<Container fluid>
						<Row ref={myRef}>
							<Col md="12">
								<Form action="" className="form-horizontal settings-form" id="TypeValidation" method="">
									<Card className="table-big-boy">
										<Card.Header>
											<div className="d-block d-md-flex align-items-center justify-content-between">
												<Card.Title as="h4">Site Settings</Card.Title>
											</div>
											<span className={errorMessage ? `` : `d-none`}>
												<label className="pl-1 pt-0 text-danger">Missing required fields</label>
											</span>
										</Card.Header>
										<Card.Body>
											<Row>
												<Col sm={12}>
													<p className="mb-4"><strong>Site Information</strong></p>
												</Col>
												<Col sm={10}>
													<Row>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">Site Title<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="text" value={settings.siteTitle} onChange={(e) => { setSettings({ ...settings, siteTitle: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.siteTitle ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.siteTitle}</label>
															</span>
														</Col>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">Company Name<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="text" value={settings.companyName} onChange={(e) => { setSettings({ ...settings, companyName: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.companyName ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.companyName}</label>
															</span>
														</Col>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">Email<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="email" value={settings.email} onChange={(e) => { setSettings({ ...settings, email: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.email ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.email}</label>
															</span>
														</Col>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">Phone<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="tel" value={settings.phone} onChange={(e) => { setSettings({ ...settings, phone: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.phone ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.phone}</label>
															</span>
														</Col>
														<Col xl={4} sm={6}>
															<Form.Group>
																<Form.Label className="d-block mb-2">Google API KEY<span className="text-danger"> *</span></Form.Label>
																<Form.Control type="text" value={settings.googleAPIKey} onChange={(e) => { setSettings({ ...settings, googleAPIKey: e.target.value }) }}></Form.Control>
															</Form.Group>
															<span className={msg.googleAPIKey ? `` : `d-none`}>
																<label className="pl-1 pt-0 text-danger">{msg.googleAPIKey}</label>
															</span>
														</Col>
													</Row>
												</Col>
												<Col sm={2}>
													<Form.Group className="site-logo-img">
														<label>Site Logo<span className="text-danger"> *</span></label>
														<div className='mb-2'>
															{<img className="img-thumbnail" src={siteLogo ? siteLogo : userDefaultImg} onError={(e) => { e.target.onerror = null; e.target.src = userDefaultImg }} style={{ width: '100px' }} />}
														</div>
														<Form.Control
															className='text-white'
															onChange={async (e) => {
																const res = await ENV.uploadImage(e);
																setSiteLogo(res ? `${ENV.uploadedImgPath}${res}` : "")
															}}
															type="file"
															accept="image/*"
														></Form.Control>
														<span className={msg.siteLogo ? `` : `d-none`}>
															<label className="pl-1 pt-0 text-danger">{msg.siteLogo}</label>
														</span>
													</Form.Group>
												</Col>
											</Row>
										</Card.Body>
										<Card.Footer>
											<Row>
												{
													permissions?.editSetting &&
													<Col sm={12} className="d-flex justify-content-end">
														<Button variant="info" onClick={submit}>Update Settings</Button>
													</Col>
												}
											</Row>
										</Card.Footer>
									</Card>
								</Form>
							</Col>
						</Row>
					</Container>
			}
		</>
	);
}
const mapStateToProps = state => ({
	settings: state.settings,
	error: state.error,
	getRoleRes: state.role.getRoleRes

});
export default connect(mapStateToProps, { getSettings, beforeSettings, editSettings, getRole })(SiteSettings);
