import { toast } from 'react-toastify';
import { GET_ERRORS, BEFORE_PROPERTY, GET_PROPERTY, ADD_PROPERTY, GET_PROPERTYS, EXPORT_PROPERTIES, GET_PROPERTYFILES, DELETE_PROPERTY, UPDATE_PROPERTY, IMPORT_PROPERTY } from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from '../../config/config';
import axios from 'axios';

export const beforeProperty = () => {
    return {
        type: BEFORE_PROPERTY
    }
}

export const importProperties = (data) => dispatch => {
    dispatch(emptyError());
    axios({
        method: 'post',
        url: `${ENV.url}property/import`,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        }
    })
        .then(data => {
            if (data?.data?.success) {
                toast.success(`${data.data.message}`);
                dispatch({
                    type: IMPORT_PROPERTY,
                    payload: data.data
                });
            } else {
                toast.error(`${data.data.message}`);
                dispatch({
                    type: GET_ERRORS,
                    payload: data.data
                })
            }
        })
        .catch(error => {
            dispatch({
                type: GET_ERRORS,
                payload: error
            })
        })
}
export const addProperty = (body) => dispatch => {
    dispatch(emptyError());
    fetch(`${ENV.url}property/create`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data?.success) {
            toast.success(`${data.message}`);
            dispatch({
                type: ADD_PROPERTY,
                payload: data
            });
        } else {
            toast.error(`${data.message}`);
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const deleteProperty = (propertyId, search) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}property/delete/${propertyId}`;
    fetch(url, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify({ search }),
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: DELETE_PROPERTY,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error?.response?.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
export const deleteBulkProperty = (propertyIds, search = {}) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}property/delete-bulk`;
    fetch(url, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify({ data: { propertyIds, search } })
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: DELETE_PROPERTY,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error?.response?.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const getProperty = (staffId) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}property/get/${staffId}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: GET_PROPERTY,
                payload: data.property
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error?.response?.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
const toQueryParams = (obj) => {
    const params = new URLSearchParams();

    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (value !== '' && value !== null && value !== undefined) {
            if (key == "listTypeOptions") {
                let valueFiltered = value.map(item => item.value)
                params.append('listType', valueFiltered.join('_'));
            } else {
                if (key != 'listType') {
                    params.append(key, value);
                }
            }
        }
    });

    return params.toString();
}
export const getPropertys = (page = 1, limit = 10, query = {}, all = "") => dispatch => {
    dispatch(emptyError());
    let url = `property/list?page=${page}&limit=${limit}&all=${all}`;
    query = toQueryParams(query);
    url += '&' + query;
    fetch(`${ENV.url}${url}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': localStorage.getItem('accessToken'),
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            console.log('data :', data);
            dispatch({
                type: GET_PROPERTYS,
                payload: data
            })
        }
        else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}
export const getPropertyFiles = (page = 1, limit = 10, query = {}, all = "") => dispatch => {
    dispatch(emptyError());
    let url = `property/listFiles?page=${page}&limit=${limit}&all=${all}`;
    query = toQueryParams(query);
    url += '&' + query;
    fetch(`${ENV.url}${url}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': localStorage.getItem('accessToken'),
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_PROPERTYFILES,
                payload: data
            })
        }
        else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const updateProperty = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}property/edit`;
    fetch(url, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message)
            dispatch({
                type: UPDATE_PROPERTY,
                payload: data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error?.response?.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};
export const exportProperties = (query = {}) => dispatch => {
    dispatch(emptyError());
    let url = `property/export`;
    query = toQueryParams(query);
    url += '?' + query;
    fetch(`${ENV.url}${url}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': localStorage.getItem('accessToken'),
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: EXPORT_PROPERTIES,
                payload: data
            })
        }
        else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}
