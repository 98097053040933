import { combineReducers } from 'redux';
import adminReducer from '../views/Admin/Admin.reducer'
import rolesReducer from '../views/AdminStaff/permissions/permissions.reducer'
import errorReducer from './shared/error/error.reducer'
import DashboardReducer from 'views/Dashboard.reducer'
import CountyReducer from 'views/County/County.reducer'
import SettingsReducer from 'views/Settings/Setting.reducer'
import PropertyReducer from 'views/Property/Property.reducer'

const rootReducer = combineReducers({
    admin: adminReducer,
    role: rolesReducer,
    error: errorReducer,
    dashboard: DashboardReducer,
    county: CountyReducer,
    settings: SettingsReducer,
    property: PropertyReducer,
});

// export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;