// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// ADMIN
export const ADD_ADMIN = 'ADD_ADMIN';
export const BEFORE_ADMIN = 'BEFORE_ADMIN';
export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const GET_ADMIN = 'GET_ADMIN';
export const GET_ADMINS = 'GET_ADMINS';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_USER_VERIFY = 'GET_USER_VERIFY';
export const BEFORE_USER_VERIFY = 'BEFORE_USER_VERIFY';
export const SET_PASSWORD = 'SET_PASSWORD';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SENT = 'VERIFY_EMAIL_SENT';


// ROLES
export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const GET_ROLE = 'GET_ROLE';
export const EXISTS_ROLE = 'EXISTS_ROLE';
export const GET_ROLES = 'GET_ROLES';
export const BEFORE_ROLE = 'BEFORE_ROLE';
export const BEFORE_PERMISSION = 'BEFORE_PERMISSION';
export const GET_PERMISSION = 'GET_PERMISSION';
export const SET_LOADER_ROLE = 'SET_LOADER_ROLE';
export const REMOVE_LOADER_ROLE = 'REMOVE_LOADER_ROLE';

// COUNTY
export const BEFORE_COUNTY = 'BEFORE_COUNTY';
export const GET_COUNTYS = 'GET_COUNTYS';
export const GET_COUNTY = 'GET_COUNTY';
export const ADD_COUNTY = 'ADD_COUNTY';
export const UPDATE_COUNTY = 'UPDATE_COUNTY';
export const DELETE_COUNTY = 'DELETE_COUNTY';

// PROPERTY
export const BEFORE_PROPERTY = 'BEFORE_PROPERTY';
export const GET_PROPERTYS = 'GET_PROPERTYS';
export const GET_PROPERTYFILES = 'GET_PROPERTYFILES';
export const GET_PROPERTY = 'GET_PROPERTY';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const IMPORT_PROPERTY = 'IMPORT_PROPERTY';
export const EXPORT_PROPERTIES = 'EXPORT_PROPERTIES';
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const DELETE_PROPERTY = 'DELETE_PROPERTY';

// DASHBOARD
export const BEFORE_DASHBOARD = 'BEFORE_DASHBOARD';
export const GET_DASHBOARD = 'GET_DASHBOARD';

// SITE SETTINGS
export const GET_SETTINGS = "GET_SETTINGS";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const BEFORE_SETTINGS = "BEFORE_SETTINGS";
