import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateProperty, addProperty, beforeProperty } from 'views/Property/Property.action';
import { Button, Form, Container, Row, Modal, Col } from "react-bootstrap";
import Select from "react-select"
import { ENV } from '../../config/config';

const PropertyModal = (props) => {

    const dispatch = useDispatch()
    const [countyOptions, setCountyOptions] = useState([])
    const [property, setProperty] = useState({
        countyId: '',
        countyName: '',
        billNumber: '',
        oldBillNumber: '',
        ownerFullName: '',
        ownerFirstName: '',
        ownerLastName: '',
        ownerMiddleName: '',
        tags: [],
        parcelId: '',
        fullAddress: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        county: '',
        billFlags: '',
        billAmount: '',
        currentDue: '',

    })

    useEffect(() => {
        setProperty(props.property)
    }, [props.property])
    useEffect(() => {
        if (Object.keys(props.countyOptions).length > 0) {
            setCountyOptions(props.countyOptions)
        }
    }, [props.countyOptions])
    const setEmpty = () => {
        setProperty({
            countyId: '',
            countyName: '',
            billNumber: '',
            oldBillNumber: '',
            ownerFullName: '',
            ownerFirstName: '',
            ownerLastName: '',
            ownerMiddleName: '',
            tags: [],
            parcelId: '',
            fullAddress: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            county: '',
            billFlags: '',
            billAmount: '',
            currentDue: '',
        })
    }
    const submit = (e) => {
        if (props.modalType === 1) {
            props.setLoader(true)
            dispatch(beforeProperty());
            dispatch(addProperty(property));
        } else {
            props.setLoader(true)
            dispatch(beforeProperty());
            dispatch(updateProperty(property));
        }
    }

    const onCloseHandler = () => {
        setEmpty()
        props.setProperty({})
        props.setPropertyModal(false)
        props.setLoader(false)
    }
    return (
        <Container fluid>
            {
                props.modalType > 0 &&
                <Modal className="modal-primary staff-modal" id="admin-modal" onHide={() => { onCloseHandler() }} show={props.propertyModal} size="xl">
                    <Modal.Header className="justify-content-center">
                        <Row>
                            <div className="col-12">
                                <h4 className="mb-0 mb-md-3 mt-0">
                                    {props.modalType === 1 ? 'Add New' : props.modalType === 2 ? 'View' : 'Edit'} Record
                                </h4>
                            </div>
                        </Row>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <Form>
                            <Row>
                                <Col md={8}>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>County </label>
                                                <Select onChange={(event) => { setProperty({ ...property, countyId: event.value, countyName: event.label }) }}
                                                    placeholder="--- Select County --- "
                                                    isDisabled={props.modalType === 2}
                                                    options={countyOptions}
                                                    value={countyOptions.filter(option => option.value === property.countyId)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Parcel Id </label>
                                                <Form.Control
                                                    placeholder="Enter parcelId"
                                                    disabled={props.modalType === 2}
                                                    type="text"
                                                    name="parcelNumber"
                                                    onChange={(e) => setProperty({ ...property, parcelId: e.target.value })}
                                                    value={property.parcelId}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Bill Amount ($)</label>
                                                <Form.Control
                                                    placeholder="Enter Bill Amount"
                                                    disabled={props.modalType === 2}
                                                    type="number"
                                                    name="billAmount"
                                                    onChange={(e) => setProperty({ ...property, billAmount: e.target.value })}
                                                    value={property.billAmount}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Current Due ($)</label>
                                                <Form.Control
                                                    placeholder="Enter Current Due"
                                                    disabled={props.modalType === 2}
                                                    type="number"
                                                    name="currentDue"
                                                    onChange={(e) => setProperty({ ...property, currentDue: e.target.value })}
                                                    value={property.currentDue}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">

                                        <Col md={4}>
                                            <Form.Group>
                                                <label>First Name </label>
                                                <Form.Control
                                                    placeholder="Enter first name"
                                                    disabled={props.modalType === 2}
                                                    type="text"
                                                    name="ownerFirstName"
                                                    onChange={(e) => setProperty({ ...property, ownerFirstName: e.target.value })}
                                                    value={ENV.capatilize(property.ownerFirstName)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Last Name </label>
                                                <Form.Control
                                                    placeholder="Enter last name"
                                                    disabled={props.modalType === 2}
                                                    type="text"
                                                    name="ownerLastName"
                                                    onChange={(e) => setProperty({ ...property, ownerLastName: e.target.value })}
                                                    value={ENV.capatilize(property.ownerLastName)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Middle Name </label>
                                                <Form.Control
                                                    placeholder="Enter middle name"
                                                    disabled={props.modalType === 2}
                                                    type="text"
                                                    name="ownerMiddleName"
                                                    onChange={(e) => setProperty({ ...property, ownerMiddleName: e.target.value })}
                                                    value={ENV.capatilize(property.ownerMiddleName)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <Form.Group>
                                                <label>Full Address </label>
                                                <Form.Control
                                                    placeholder="Enter Full Address"
                                                    disabled={props.modalType === 2}
                                                    type="text"
                                                    name="fullAddress"
                                                    onChange={(e) => setProperty({ ...property, fullAddress: e.target.value })}
                                                    value={ENV.capatilize(property.fullAddress)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Address </label>
                                                <Form.Control
                                                    placeholder="Enter Address"
                                                    disabled={props.modalType === 2}
                                                    type="text"
                                                    name="address"
                                                    onChange={(e) => setProperty({ ...property, address: e.target.value })}
                                                    value={ENV.capatilize(property.address)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>City</label>
                                                <Form.Control
                                                    placeholder="Enter City"
                                                    disabled={props.modalType === 2}
                                                    type="text"
                                                    name="city"
                                                    onChange={(e) => setProperty({ ...property, city: e.target.value })}
                                                    value={ENV.capatilize(property.city)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>State</label>
                                                <Form.Control
                                                    placeholder="Enter state"
                                                    disabled={props.modalType === 2}
                                                    type="text"
                                                    name="state"
                                                    onChange={(e) => setProperty({ ...property, state: e.target.value })}
                                                    value={ENV.capatilize(property.state)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Zip Code </label>
                                                <Form.Control
                                                    placeholder="Enter zipCode"
                                                    disabled={props.modalType === 2}
                                                    type="text"
                                                    name="zipCode"
                                                    onChange={(e) => setProperty({ ...property, zipCode: e.target.value })}
                                                    value={property.zipCode}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <label>Tags</label>
                                        <div className="tags-input-container">
                                            <Form.Control
                                                placeholder="Enter tags and press Enter"
                                                type="text"
                                                disabled={props.modalType === 2}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        const value = e.target.value.trim();
                                                        if (value) {
                                                            const currentTags = property.tags || [];
                                                            if (!currentTags.includes(value)) {
                                                                setProperty({
                                                                    ...property,
                                                                    tags: [...currentTags, value]
                                                                });
                                                            }
                                                            e.target.value = '';
                                                        }
                                                    }
                                                }}
                                            />
                                            <div className="tags-container mt-2">
                                                {property.tags && property.tags.map((tag, index) => (
                                                    <span key={index} className="tag-pill">
                                                        {tag}
                                                        {props.modalType !== 2 && (
                                                            <span 
                                                                className="remove-tag"
                                                                onClick={() => {
                                                                    const newTags = property.tags.filter((_, i) => i !== index);
                                                                    setProperty({
                                                                        ...property,
                                                                        tags: newTags
                                                                    });
                                                                }}
                                                            >
                                                                ×
                                                            </span>
                                                        )}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        <style jsx="true">{`
                                            .tags-input-container {
                                                width: 100%;
                                            }
                                            .tags-container {
                                                display: flex;
                                                flex-wrap: wrap;
                                                gap: 8px;
                                            }
                                            .tag-pill {
                                                background: #e9ecef;
                                                border-radius: 16px;
                                                padding: 4px 12px;
                                                font-size: 14px;
                                                display: flex;
                                                align-items: center;
                                            }
                                            .remove-tag {
                                                margin-left: 8px;
                                                cursor: pointer;
                                                font-weight: bold;
                                                font-size: 16px;
                                            }
                                            .remove-tag:hover {
                                                color: #dc3545;
                                            }
                                        `}</style>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className="btn btn-warning" onClick={() => { onCloseHandler(); }}>Close</Button>
                        {
                            props.modalType === 2 ? '' :
                                <Button className="btn btn-info" onClick={() => { submit() }} /* disabled={isLoader} */>Save</Button>
                        }
                    </Modal.Footer>
                </Modal>
            }
        </Container>
    )
}

export default PropertyModal;