import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function DashboardCard(props) {
    return (
        <Link to={props.link ?? '#' } target="_blank" rel="noreferrer noopener">
            <Card className="card-stats">
                <Card.Body>
                    <div className="d-flex">
                        <div className="numbers">
                            <p className="card-category">{props.title}</p>
                            <Card.Title as="h4">{props.count}</Card.Title>
                        </div>
                        <div className="icon-big text-center icon-warning">
                            <FontAwesomeIcon icon={props.icon} />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Link>
    );
}


export default DashboardCard;
